import React from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App"; // Assuming App is still a component you might want to use
import LoginPage from "./features/RegisterAndLoginPages/LoginPage";
import RegisterPage from "./features/RegisterAndLoginPages/RegisterPage";
import ResetPasswordPage from "./features/RegisterAndLoginPages/ResetPasswordPage";
import WelcomePage from "./features/WelcomePage/WelcomePage";
import ConversationPage from "./features/ConversationPage/ConversationPage";
import AdminPage from "./features/AdminPage/AdminPage";
import WordBankPage from "./features/WordBankPage/WordBankPage.js";
import reportWebVitals from "./reportWebVitals";
import { WebSocketProvider } from "./components/WebSocketProvider"; // Path to your WebSocketProvider

import "@material/web/menu/menu.js";
import "@material/web/menu/menu-item.js";
import "@material/web/menu/sub-menu.js";

import "@material/web/button/filled-button.js";
import "@material/web/button/outlined-button.js";
import "@material/web/button/text-button.js";
import "@material/web/button/elevated-button.js";
import "@material/web/button/filled-tonal-button.js";

import "@material/web/textfield/filled-text-field.js";
import "@material/web/textfield/outlined-text-field.js";

import "@material/web/icon/icon.js";
import "@material/web/iconbutton/filled-icon-button.js";
import "@material/web/iconbutton/filled-tonal-icon-button.js";
import "@material/web/iconbutton/icon-button.js";
import "@material/web/iconbutton/outlined-icon-button.js";

import "@material/web/elevation/elevation.js";

import "@material/web/checkbox/checkbox.js";

import "@material/web/dialog/dialog.js";

import "@material/web/select/outlined-select.js";
import "@material/web/select/filled-select.js";
import "@material/web/select/select-option.js";

import "@material/web/switch/switch.js";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement); // Now using createRoot from react-dom/client
root.render(
  <React.StrictMode>
    <WebSocketProvider>
      <Router>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/resetPassword" element={<ResetPasswordPage />} />
          <Route path="/conversation" element={<ConversationPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/app" element={<App />} />
          <Route path="/wordBank" element={<WordBankPage />} />
        </Routes>
      </Router>
    </WebSocketProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
