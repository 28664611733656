import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const wsRef = useRef(null);
    const reconnectAttempts = useRef(0);
    const handleReconnectRef = useRef(null);
    const heartbeatInterval = useRef(null);

    // Function to start the heartbeat
    const startHeartbeat = (wsInstance) => {
        clearInterval(heartbeatInterval.current);
        heartbeatInterval.current = setInterval(() => {
            const pingMessage = JSON.stringify({ type: 'ping' });
            wsInstance.send(pingMessage);
        }, 30000);
    };

    const connectWebSocket = useCallback(() => {
        const newWs = new WebSocket(process.env.REACT_APP_WS_URL);

        setWs(newWs);
        wsRef.current = newWs;

        newWs.onopen = () => {
            reconnectAttempts.current = 0;
            startHeartbeat(newWs);
            console.log('WebSocket connected');
        };

        newWs.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        newWs.onclose = (event) => {
            clearInterval(heartbeatInterval.current);
            if (!event.wasClean) {
                handleReconnectRef.current();
            }
            console.log('WebSocket closed', event);
        };

        newWs.onmessage = (event) => {
            if (event.data) {
                const messageObj = JSON.parse(event.data);
                if (messageObj.type === 'pong') {
                    // Handle pong response
                } else {
                    // Handle other messages
                }
            }
        };
    }, []);

    const initializeCookieAndConnectRef = useRef(null);

    const initializeCookieAndConnect = useCallback(() => {
        fetch(`${process.env.REACT_APP_API_URL}/set-cookie`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(response => {
                connectWebSocket();
            })
            .catch(error => {
                console.error('Error setting cookie:', error);
                if (handleReconnectRef.current) {
                    handleReconnectRef.current();
                }
            });
    }, [connectWebSocket]);

    const handleReconnect = useCallback(() => {
        reconnectAttempts.current += 1;
        const backoffDelay = Math.min(1000 * (2 ** reconnectAttempts.current), 30000);
        setTimeout(() => {
            if (initializeCookieAndConnectRef.current) {
                initializeCookieAndConnectRef.current();
            }
        }, backoffDelay);
    }, []);

    const retryConnection = useCallback(() => {
        console.log("Attempting reconnect");
        if (wsRef.current) {
            wsRef.current.close();
        }
        // Delay to ensure cleanup before reconnecting
        setTimeout(() => {
            connectWebSocket();
        }, 1000);
    }, [connectWebSocket]);

    useEffect(() => {
        handleReconnectRef.current = handleReconnect;
        initializeCookieAndConnectRef.current = initializeCookieAndConnect;
    }, [handleReconnect, initializeCookieAndConnect]);

    useEffect(() => {
        initializeCookieAndConnect();

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
            clearInterval(heartbeatInterval.current);
        };
    }, [initializeCookieAndConnect]);

    return (
        <WebSocketContext.Provider value={{ ws, retryConnection }}>
            {children}
        </WebSocketContext.Provider>
    );
};
