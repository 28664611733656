import React from "react";

export const ConfirmationDialog = ({
  message,
  details,
  onClose,
  onConfirm,
  confirmButtonLabel = "Confirm",
  cancelButtonLabel = "Cancel",
}) => {
  return (
    <div className="confirmation-dialog-overlay">
      <div className="confirmation-dialog-container">
        <p className="confirmation-dialog-message">{message}</p>
        {details && (
          <p className="confirmation-dialog-details">
            <strong>{details.label}:</strong> {details.value}
          </p>
        )}
        <div className="confirmation-dialog-buttons">
          <md-text-button onClick={onClose}>
            <md-icon slot="icon">{cancelButtonLabel}</md-icon>
            {cancelButtonLabel}
          </md-text-button>
          <md-filled-tonal-button onClick={onConfirm}>
            <md-icon slot="icon">check_circle</md-icon>
            {confirmButtonLabel}
          </md-filled-tonal-button>
        </div>
      </div>
    </div>
  );
};
