import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import './WelcomePage.css';

function Header() {
    return (
        <header className="welcome-header">
            <Link to="/" className="home-button">Palyglot</Link>
            <div className="navigation-links">
                <Link to="/register">Register</Link> |
                <Link to="/login">Login</Link> |
                {/* <Link to="/app">Go to Conversation Page</Link> 
                This seems to be broken if you aren't already logged in*/}
            </div>
        </header>
    );
}
function HeroSection() {
    return (
        <div className="hero-section">
            <h1>Master Language Through Conversation</h1>
            <p>Learn only what you need through tailored conversations with AI and learning partners.</p>
            <p>Ditch traditional flashcards. Overcome language barriers without the fear of small errors holding you back.</p>
            <p>Perfect is the enemy of done.</p>
            <Link to="/register" className="cta-button-link">
                <md-filled-button className="cta-button">Get Started</md-filled-button>
            </Link>
        </div>
    );
}
function FeaturesHighlightSection() {
    return (
        <div className="features-section">
            <div className="feature">
                <h3>Dynamic Learning Path</h3>
                <p>Adapts your learning experience based on your progress and interactions.</p>
            </div>
            <div className="feature">
                <h3>Vocabulary Highlighting</h3>
                <p>Focus on what's important with highlighted key vocabulary from your conversations.</p>
            </div>
            <div className="feature">
                <h3>Efficiency in Learning</h3>
                <p>Learn effectively by focusing on vocabulary tailored to your specific needs.</p>
            </div>
        </div>
    );
}
function HowItWorksSection() {
    return (
        <div className="how-it-works-section">
            <h2>How It Works</h2>
            <div className="steps-container">
                <div className="step">
                    <div className="step-icon">
                        <md-icon>flag</md-icon>
                    </div>
                    <h3>Set Your Learning Goals</h3>
                    <p>Specify your reasons for learning a new language to personalize your learning path.</p>
                </div>
                <div className="step">
                    <div className="step-icon">
                        <md-icon>chat</md-icon>
                    </div>
                    <h3>Engage in Tailored Scenarios</h3>
                    <p>Participate in conversations generated by our scenario manager, targeting your learning objectives.</p>
                </div>
                <div className="step">
                    <div className="step-icon">
                        <md-icon>translate</md-icon>
                    </div>
                    <h3>Learn Contextually Relevant Vocabulary</h3>
                    <p>Master the vocabulary you need through targeted conversations, with key words highlighted for efficient learning.</p>
                </div>
            </div>
        </div>
    );
}
function Testimonials(){
    return (
        <div className="testimonials-section">
            <h2 className="testimonials-title">What Our Learners Say</h2>
            <div className="testimonials-container">
                {/* Example of a single testimonial */}
                <div className="testimonial">
                    <p className="testimonial-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac mattis metus. Quisque viverra, arcu sed gravida consectetur, velit arcu convallis nunc, at fermentum lacus quam at sapien. Nunc accumsan consectetur suscipit. Fusce est purus, efficitur vel maximus id, iaculis at lacus."</p>
                    <p className="testimonial-author">- John Doe</p>
                </div>
                {/* Repeat for other testimonials */}
            </div>
        </div>
    );
};
function InteractiveDemoSection(){
    return (
        <div className="interactive-demo-section">
            <h2>Try a Quick Demo</h2>
            <p>Enter your learning goal to generate a conversation scenario.</p>
            <input type="text" placeholder="Type your learning goal here..." className="demo-input" />
            <button className="demo-submit-button">Generate Scenario</button>
            <div className="demo-output">
                {/* Simulated conversation output will go here */}
            </div>
        </div>
    );
};

function PricingAndPlansSection() {
    return (
        <div className="pricing-section">
            <h2>Choose Your Plan</h2>
            <div className="plans-container">
                <div className="plan">
                    <h3>Basic</h3>
                    <p>Perfect for beginners looking to get started.</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                    <button>Subscribe</button>
                </div>
                <div className="plan">
                    <h3>Premium</h3>
                    <p>For dedicated learners wanting to accelerate their progress.</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                    <button>Subscribe</button>
                </div>
                <div className="plan">
                    <h3>Supporter</h3>
                    <p>For learners wanting to support free tier users and the app's development.</p>
                    <ul>
                        <li>Supports free tier users.</li>
                        <li>Prioratized feedback and technical support.</li>
                        <li>Supporter tier forms for discussion.</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                    <button>Subscribe</button>
                </div>
            </div>
        </div>
    );
}
function FAQSection() {
    return (
        <div className="faq-section">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
            <div className="faq-container">
                <div className="faq-item">
                    <h3 className="faq-question">How does the AI tailor my learning?</h3>
                    <p className="faq-answer">The AI uses your learning goals to generate conversation scenarios, allowing words and syntaxes to emerge naturally based on their frequency in these scenarios. This approach ensures efficient and relevant learning without fixed paths.</p>
                </div>
                <div className="faq-item">
                    <h3 className="faq-question">Can I change my learning goals?</h3>
                    <p className="faq-answer">Yes, you can update your learning goals anytime. The AI will adapt by creating new scenarios that reflect your updated objectives, maintaining a natural and relevant learning experience.</p>
                </div>
                <div className="faq-item">
                    <h3 className="faq-question">What languages does the app support?</h3>
                    <p className="faq-answer">The app supports multiple languages based on the AI's training. However, the performance quality varies by language due to differences in the amount of training data available. While major languages are well-supported, you may experience varying levels of accuracy with less common languages.</p>
                </div>
            </div>
        </div>
    );
}

function FinalCTASection() {
    return (
        <div className="cta-section">
            <h2 className="cta-heading">Ready to Transform Your Language Learning Journey?</h2>
            <Link to="/register" className="cta-button-link">
                <md-filled-button className="cta-button">Sign Up Now!</md-filled-button>
            </Link>
        </div>
    );
}
function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <nav className="footer-nav">
                    <a href="/about">About Us</a>
                    <a href="/contact">Contact</a>
                    <a href="/privacy">Privacy Policy</a>
                    <a href="/terms">Terms of Service</a>
                </nav>
                <div className="social-media">
                    <a href="https://facebook.com">Facebook</a>
                    <a href="https://twitter.com">Twitter</a>
                    <a href="https://instagram.com">Instagram</a>
                </div>
                <div className="newsletter-signup">
                    <input type="email" placeholder="Subscribe to our newsletter" />
                    <button type="submit">Subscribe</button>
                </div>
            </div>
        </footer>
    );
}

function WelcomePage() {
    function adjustBannerAngle() {
        const elements = document.querySelectorAll('.construction-wrap');

        elements.forEach(element => {
            const width = element.offsetWidth;
            const height = element.offsetHeight;

            // Calculate angle based on the aspect ratio
            const angle = Math.atan(height / width) * (180 / Math.PI);

            // Set the angle for the ::before pseudo-element
            const beforeElementStyle = `rotate(${angle}deg)`;
            element.style.setProperty('--banner-angle', beforeElementStyle);
        });
    }

    useEffect(() => {
        // Initial adjustment
        adjustBannerAngle();

        // Adjust on window resize
        window.addEventListener('resize', adjustBannerAngle);
    }, []); 
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');
    useEffect(() => {
        import(`../../themes/${theme}.css`)
            .then(() => {
            })
            .catch(err => {
                console.error(`Failed to load ${theme} theme`, err);
            });
        document.body.className = theme;
    }, [theme]); // Re-run effect when theme changes

    return (
        <div className="welcome">
            <Header />
            <HeroSection />
            <HowItWorksSection />
            <FeaturesHighlightSection />
            <div className="construction-wrap">
                <Testimonials />
            </div>
            <div className="construction-wrap">
                <PricingAndPlansSection />
            </div>
            <div className="construction-wrap">
                <InteractiveDemoSection />
            </div>
            <FAQSection />
            <FinalCTASection />
            <div className="construction-wrap">
                <Footer />
            </div>
        </div>
    );
}

export default WelcomePage;