import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import "./SidePanel.css";
import { Link, useNavigate } from "react-router-dom";

const SidePanel = ({
  isPanelOpen,
  togglePanel,
  conversationDetails,
  setConversationDetails,
  setInitialConversationDetails,
  setScenarioState,
  handleResetConversation,
  userType,
  menuButtonRef,
  language,
  setLanguage,
  showTransliteration,
  setShowTransliteration,
  theme,
  setTheme,
  masteryThreshold,
  setMasteryThreshold,
}) => {
  const navigate = useNavigate();

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const dialogRefSettings = useRef(null);
  const openSettingsDialog = () => {
    dialogRefSettings.current.open = true;
  };

  const clearConversation = () => {
    setConversationDetails({
      conversationId: null,
      conversationHistory: [],
    });
    setInitialConversationDetails({
      conversationId: null,
      conversationHistory: [],
    });
    setScenarioState({
      reasonForLearning: "",
      additionalNotes: "",
      languageLearnerRole: "",
      conversationPartnerRole: "",
      informationToGive: "",
      informationToRequest: "",
      context: "",
    });
    handleResetConversation();
  };

  const fetchConversationById = useCallback(
    async (conversation) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get-conversation/${conversation._id}`
        );

        const { _id, sentences } = response.data;

        const transformedData = sentences.map((sentence) => {
          return {
            id: sentence.id,
            sender: sentence.sender,
            targetLanguageMessage: sentence.targetLanguageMessage,
            originalMessage: sentence.originalMessage,
            morphemeTranslationDetails: sentence.morphemeTranslationDetails,
            fullSentenceTranslation: sentence.fullSentenceTranslation,
            transliteration: sentence.transliteration,
          };
        });
        // Include the conversationId in the conversationHistory state
        const conversationId = _id;
        const conversationHistory = transformedData;
        setConversationDetails({ conversationId, conversationHistory });
        setInitialConversationDetails({ conversationId, conversationHistory });

        setScenarioState(response.data.scenario);

        setSelectedConversation(conversation);
      } catch (error) {
        console.error("Error fetching conversation:", error);
      }
    },
    [setConversationDetails, setInitialConversationDetails, setScenarioState]
  );

  const handleDeleteConversation = async (conversationId) => {
    try {
      //console.log("Deleting conversation with ID:", conversationId);
      if (conversationId === conversationDetails.conversationId) {
        clearConversation();
      }
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/delete-conversation/${conversationId}`
      );
      fetchConversations();
      if (response.status === 200) {
        //console.log("Conversation deleted successfully");
      } else {
        console.error("Failed to delete conversation:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };
  const fetchConversations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-conversation-titles`
      );
      setConversations(response.data);
    } catch (error) {
      console.error("Error fetching conversation titles:", error);
    }
  };
  useEffect(() => {
    fetchConversations();

    const handleClickOutside = (event) => {
      if (
        event.target.classList.contains("side-panel") ||
        event.target.closest(".side-panel") ||
        menuButtonRef.current.contains(event.target)
      ) {
        return;
      }
      if (isPanelOpen) {
        togglePanel();
      }
    };

    if (isPanelOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPanelOpen, togglePanel]);

  useEffect(() => {
    const setupMenuToggle = (menuSelector, anchorSelector) => {
      const toggleMenu = () => {
        const menuEl = document.body.querySelector(menuSelector);
        if (menuEl) {
          menuEl.open = !menuEl.open;
        }
      };

      const anchorEl = document.body.querySelector(anchorSelector);
      if (anchorEl) {
        anchorEl.addEventListener("click", toggleMenu);
      }

      return () => {
        if (anchorEl) {
          anchorEl.removeEventListener("click", toggleMenu);
        }
      };
    };

    const cleanupFunctions = conversations.map((conversation) => {
      const buttonId = `conversation-actions-menu-anchor-${conversation._id}`;
      const menuId = `conversation-actions-menu-${conversation._id}`;
      return setupMenuToggle(`#${menuId}`, `#${buttonId}`);
    });

    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [conversations]);

  return (
    <div className="conversation-side-panel">
      <div className={`side-panel ${isPanelOpen ? "open" : ""}`}>
        <div className="side-panel-selection">
          <h3>Pages</h3>
          <li onClick={() => navigate("/")}>Home</li>
          <li onClick={() => navigate("/wordBank")}>
            <div>Word Bank</div>
          </li>
          <li onClick={() => navigate("/resetPassword")}>
            <div>Reset Password</div>
          </li>
          {userType === "admin" && (
            <li onClick={() => navigate("/login")}>
              <div>Login Page</div>
            </li>
          )}
          {userType === "admin" && (
            <li onClick={() => navigate("/admin")}>
              <div>Admin Page</div>
            </li>
          )}
          <h3 className="h3-select-conversation">
            <span>Select Conversation</span>
            <md-icon-button onClick={clearConversation}>
              <md-icon>edit_square</md-icon>
            </md-icon-button>
          </h3>
          <ul>
            {conversations.map((conversation) => {
              const buttonId = `conversation-actions-menu-anchor-${conversation._id}`;
              const menuId = `conversation-actions-menu-${conversation._id}`;
              return (
                <li
                  key={conversation._id}
                  className={
                    conversation._id === selectedConversation?._id
                      ? "selected"
                      : ""
                  }
                  onClick={async () => {
                    await fetchConversationById(conversation);
                  }}
                >
                  {conversation.title}
                  <span className="inline-flex-container">
                    <md-icon-button
                      id={buttonId}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <md-icon>more_horiz</md-icon>
                    </md-icon-button>
                    <md-menu id={menuId} anchor={buttonId}>
                      <md-menu-item
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteConversation(conversation._id);
                        }}
                      >
                        <span style={{ color: "var(--md-sys-color-error)" }}>
                          Delete
                        </span>
                        <md-icon
                          style={{ color: "var(--md-sys-color-error)" }}
                          slot="end"
                        >
                          delete
                        </md-icon>
                      </md-menu-item>
                    </md-menu>
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="settings-button">
            <li onClick={openSettingsDialog}>
              <md-icon>settings</md-icon>
              Settings
            </li>
          </div>
        </div>
        <SettingsDialog
          dialogRefSettings={dialogRefSettings}
          language={language}
          setLanguage={setLanguage}
          showTransliteration={showTransliteration}
          setShowTransliteration={setShowTransliteration}
          theme={theme}
          setTheme={setTheme}
          masteryThreshold={masteryThreshold}
          setMasteryThreshold={setMasteryThreshold}
        />
      </div>
    </div>
  );
};

const SettingsDialog = ({
  dialogRefSettings,
  language,
  setLanguage,
  showTransliteration,
  setShowTransliteration,
  theme,
  setTheme,
  masteryThreshold,
  setMasteryThreshold,
}) => {
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const targetLanguageOptions = [
    "French",
    "Japanese",
    "Korean",
    "German",
    "Other",
  ];
  useEffect(() => {
    fetchPreferences();
    setIsOtherSelected(false);
  }, []);
  const handleLanguageChange = async (newLanguage) => {
    // Update the language preference
    setLanguage(newLanguage);

    // List of languages that use non-Roman scripts
    const nonRomanLanguages = [
      "Japanese",
      "Korean",
      "Armenian",
      "Russian",
      "Persian",
      "Traditional Chinese",
      "Simplified Chinese",
      "Mandarin",
      "Arabic",
    ];

    // Check if the selected language is in the list of non-Roman languages
    if (nonRomanLanguages.includes(newLanguage)) {
      setShowTransliteration(true);
      await updatePreferences({ showTransliteration: true });
    }
    await updatePreferences({ language: newLanguage });
  };

  const updatePreferences = async (newPreferences) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/update-preferences`,
        newPreferences,
        {
          withCredentials: true,
        }
      );
      await fetchPreferences(); // Fetch preferences to update UI
    } catch (error) {
      console.error("Error updating preferences:", error);
      console.error("Error details:", error.response);
    }
  };

  const fetchPreferences = async () => {
    let response;
    try {
      response = await fetch(
        `${process.env.REACT_APP_API_URL}/get-preferences`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const prefs = await response.json();
      if (prefs.message === "User authentication required") {
        console.error(
          prefs.message,
          "User authentication required for fetchPreferences"
        );
      } else {
        setLanguage(prefs.language);
        setShowTransliteration(prefs.showTransliteration);
        setTheme(prefs.theme);
        setMasteryThreshold(prefs.masteryThreshold);
      }
      //console.log("prefs", prefs);
    } catch (error) {
      console.error("Error fetching preferences:", error);
    }
  };

  const selectLanguage = (event) => {
    const selectedLanguage = event.target.value;
    // Return early if other is selected
    if (selectedLanguage === "Other") {
      setIsOtherSelected(true);
      return;
    }
    setIsOtherSelected(false);
    handleLanguageChange(selectedLanguage);
  };

  const handleOtherLanguageChange = (event) => {
    const otherLanguage = event.target.value;
    handleLanguageChange(otherLanguage);
  };

  const handleMasteryThresholdChange = (event) => {
    const selectedMasteryThreshold = event.target.value;
    if (!selectedMasteryThreshold) return;
    updatePreferences({ masteryThreshold: selectedMasteryThreshold });
  };

  const handleTransliterationVisibilityChange = (event) => {
    const visibility = event.target.selected;
    updatePreferences({ showTransliteration: visibility });
  };

  const handleThemeChange = (event) => {
    const isSelected = event.target.selected;
    const selectedTheme = !isSelected ? "light" : "dark";
    updatePreferences({ theme: selectedTheme });
  };

  const handleSubmit = (e) => {
    dialogRefSettings.current.open = false;
  };
  const closeDialog = (e) => {
    dialogRefSettings.current.open = false;
  };
  return (
    <div className="settings-dialog">
      <md-dialog ref={dialogRefSettings}>
        <span slot="headline" className="headline">
          Settings
          <md-icon-button
            class="dialog-close-button"
            value="close"
            aria-label="Close settings"
            onClick={() => {
              closeDialog();
            }}
          >
            <md-icon>close</md-icon>
          </md-icon-button>
        </span>
        <form
          className="settings-form"
          slot="content"
          method="dialog"
          onSubmit={handleSubmit}
        >
          <div>
            <md-filled-select
              required
              label="Target Language"
              onInput={selectLanguage}
              value={language}
            >
              <md-select-option selected value={language} aria-label={language}>
                <div slot="headline">{language}</div>
              </md-select-option>
              {targetLanguageOptions.map((lang) => (
                <md-select-option key={lang} value={lang}>
                  <div slot="headline">{lang}</div>
                </md-select-option>
              ))}
            </md-filled-select>
          </div>
          {isOtherSelected && (
            <div>
              <md-filled-text-field
                label="Enter Other Language"
                id="otherLanguageInput"
                placeholder="Enter language"
                value={language}
                onBlur={handleOtherLanguageChange}
                required
              />
            </div>
          )}
          <div>
            <md-filled-text-field
              label="Mastery Threshold"
              supporting-text="Number of views to mark word as mastered"
              editable
              type="number"
              value={masteryThreshold}
              required
              onInput={handleMasteryThresholdChange}
            />
          </div>
          <div>
            <label className="settings-toggle">
              Pronunciation
              <md-switch
                selected={showTransliteration ? true : undefined}
                onInput={handleTransliterationVisibilityChange}
              ></md-switch>
            </label>
          </div>
          <div>
            <label className="settings-toggle">
              <span>Dark Theme</span>
              <md-switch
                selected={theme === "dark" ? true : undefined}
                onInput={handleThemeChange}
              ></md-switch>
            </label>
          </div>
        </form>
      </md-dialog>
    </div>
  );
};

export default React.memo(SidePanel);
