import React, { useState, useEffect, useRef, useCallback } from "react";
import "./WordBankPage.css";
import "../../App.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useWebSocket } from "../../components/WebSocketProvider"; // Import the useWebSocket hook

axios.defaults.withCredentials = true;

function WordBankPage() {
  const [theme, setTheme] = useState("dark");
  const { ws, retryConnection } = useWebSocket();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedView, setSelectedView] = useState({
    dataKey: "notMasteredWordData",
    dataTitle: "Words to Learn",
  });
  const [wordData, setWordData] = useState([]);
  const [notMasteredWordData, setNotMasteredWordData] = useState([]); // Add state for not mastered words
  const [masteredWordData, setMasteredWordData] = useState([]);

  const [isPopupMenuVisible, setIsPopupMenuVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const menuButtonRef = useRef(null);

  const togglePanel = useCallback(() => {
    setIsPanelOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  useEffect(() => {
    if (theme) {
      import(`../../themes/${theme}.css`)
        .then(() => {})
        .catch((err) => {
          console.error(`Failed to load ${theme} theme`, err);
        });
      document.body.className = theme;
    }
  }, [theme]); // Re-run effect when theme changes

  useEffect(() => {
    retryConnection();
  }, [retryConnection]);

  useEffect(() => {
    if (!ws) return;

    const onMessage = (event) => {
      const data = JSON.parse(event.data);
      //console.log("data: ", data);
    };

    ws.addEventListener("message", onMessage);
    return () => {
      if (ws) {
        ws.removeEventListener("message", onMessage);
      }
    };
  }, [ws]);
  let wordList = wordData;
  switch (selectedView.dataKey) {
    case "wordData":
      wordList = wordData;
      break;
    case "notMasteredWordData":
      wordList = notMasteredWordData;
      break;
    case "masteredWordData":
      wordList = masteredWordData;
      break;
    default:
      wordList = [];
  }

  const fetchMorphemes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-morphemes`,
        {
          withCredentials: true,
        }
      );

      const { morphemes, masteryThreshold } = response.data;

      // Sort the data by trueCount in descending order
      const sortedData = morphemes.sort((a, b) => b.trueCount - a.trueCount);
      //console.log("sortedData", sortedData);
      setWordData(sortedData);

      // Filter the mastered morphemes and update the state
      const mastered = sortedData.filter(
        (morpheme) => morpheme.effectiveCount >= masteryThreshold
      );
      setMasteredWordData(mastered);

      // Filter the not mastered morphemes and update the state
      const notMastered = sortedData.filter(
        (morpheme) => morpheme.effectiveCount < masteryThreshold
      );
      setNotMasteredWordData(notMastered);

      return sortedData;
    } catch (error) {
      console.error("Error fetching morphemes:", error);
      console.error("Error details:", error.response);
      throw error;
    }
  };

  const updateMorphemesStatus = async (selectedRows, endpoint) => {
    try {
      const morphemeIds = selectedRows.map((row) => row._id);
  
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/${endpoint}`,
        { morphemeIds },
        {
          withCredentials: true,
        }
      );
  
      // Fetch and update the morphemes data
      await fetchMorphemes();
      setSelectedRows([]);
      return response.data;
    } catch (error) {
      console.error("Error updating morphemes:", error);
      throw error;
    }
  };
  
  const updateMorphemesToMastered = (selectedRows) =>
    updateMorphemesStatus(selectedRows, "update-morphemes-as-mastered");
  
  const updateMorphemesToNotMastered = (selectedRows) =>
    updateMorphemesStatus(selectedRows, "update-morphemes-as-not-mastered");
  

  useEffect(() => {
    fetchMorphemes();
  }, []);

  useEffect(() => {
    retryConnection();
  }, [retryConnection]);

  const handleRowClick = (row) => {
    setSelectedRows((prevSelectedRows) => {
      const isRowAlreadySelected = prevSelectedRows.some(
        (selectedRow) => selectedRow._id === row._id
      );
      if (isRowAlreadySelected) {
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow._id !== row._id
        );
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const isRowSelected = (row) => {
    return selectedRows.some((selectedRow) => selectedRow._id === row._id);
  };


  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsPopupMenuVisible(true);
    } else if (isPopupMenuVisible) {
      triggerCloseAnimation();
    }
  }, [selectedRows]);

  const triggerCloseAnimation = () => {
    setIsClosing(true);
  };

  const handleAnimationEnd = () => {
    if (isClosing) {
      setIsPopupMenuVisible(false);
      setIsClosing(false);
    }
  };

  return (
    <div className={theme}>
      <div className="App">
        <div className="word-bank-container">
          <header className="word-bank-header">
            <span style={{ marginRight: "auto" }}>
              <md-icon-button ref={menuButtonRef} onClick={togglePanel}>
                <md-icon>menu</md-icon>
              </md-icon-button>
            </span>
          </header>
          <SidePanel
            isOpen={isPanelOpen}
            togglePanel={togglePanel}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            menuButtonRef={menuButtonRef}
          />
          <div className="word-bank-area">
            <span className="word-bank-title">{selectedView.dataTitle}</span>
            <div className="word-bank-table-container">
              <div className="word-bank-table">
                <div className="word-bank-header-row">
                  <div className="word-bank-header-item">Word</div>
                  <div className="word-bank-header-item">Transliteration</div>
                  <div className="word-bank-header-item">Translation</div>
                  {/* <div className="word-bank-header-item">True Count</div> */}
                  <div className="word-bank-header-item">Effective Count</div>
                </div>
                {wordList.map((item) => (
                  <div
                    key={item._id}
                    className={`word-bank-row ${
                      isRowSelected(item) ? "selected" : ""
                    }`}
                    onClick={() => handleRowClick(item)}
                  >
                    <div className="word-bank-item">
                      {item.originalMorpheme}
                    </div>
                    <div className="word-bank-item">
                      {item.transliteratedMorpheme}
                    </div>
                    <div className="word-bank-item">
                      {item.translatedMorpheme}
                    </div>
                    {/* <div className="word-bank-item">{item.trueCount}</div> */}
                    <div className="word-bank-item">{item.effectiveCount}</div>
                  </div>
                ))}
              </div>
            </div>
            {isPopupMenuVisible && (
              <div
                className={`popup-menu ${isClosing ? "close" : ""}`}
                onAnimationEnd={handleAnimationEnd}
              >
                {(selectedView.dataKey === "notMasteredWordData" ||
                  selectedView.dataKey === "wordData") && (
                  <md-filled-tonal-button
                    onClick={() => updateMorphemesToMastered(selectedRows)}
                  >
                    Mark as Mastered
                  </md-filled-tonal-button>
                )}
                {(selectedView.dataKey === "masteredWordData" ||
                  selectedView.dataKey === "wordData") && (
                  <md-filled-tonal-button
                    onClick={() => updateMorphemesToNotMastered(selectedRows)}
                  >
                    Mark as Not Mastered
                  </md-filled-tonal-button>
                )}
                <md-filled-tonal-button onClick={() => setSelectedRows([])}>
                  Clear Selection
                </md-filled-tonal-button>
                <md-filled-tonal-icon-button onClick={triggerCloseAnimation}>
                  <md-icon>close</md-icon>
                </md-filled-tonal-icon-button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
const SidePanel = ({
  isOpen,
  togglePanel,
  selectedView,
  setSelectedView,
  menuButtonRef,
}) => {
  const navigate = useNavigate();

  const wordDataTitles = {
    notMasteredWordData: "Words to Learn",
    masteredWordData: "Mastered Words",
    wordData: "All Words",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickOnSidePanel = event.target.classList.contains("side-panel");
      const isClickInsideSidePanel = event.target.closest(".side-panel");
      const isClickOnMenuButton = menuButtonRef.current.contains(event.target);

      if (isClickOnSidePanel || isClickInsideSidePanel || isClickOnMenuButton) {
        return;
      }

      if (isOpen) {
        togglePanel();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, togglePanel]);

  const handleItemClick = (dataKey) => {
    setSelectedView({
      dataKey: dataKey,
      dataTitle: wordDataTitles[dataKey],
    });
    togglePanel();
  };

  return (
    <>
      <div className={`side-panel ${isOpen ? "open" : ""}`}>
        <div className="side-panel-selection">
          <h3>Pages</h3>
          <li onClick={() => navigate("/")}>Home</li>
          <li onClick={() => navigate("/app")}>Conversation</li>
          <h3>Views</h3>
          <ul>
            {Object.keys(wordDataTitles).map((key) => (
              <li
                key={key}
                className={selectedView.dataKey === key ? "selected" : ""}
                onClick={() => handleItemClick(key)}
              >
                {wordDataTitles[key]}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default WordBankPage;
