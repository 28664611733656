import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../App.css';
import './RegisterAndLoginPages.css';

function LoginPage() {
	return (
		<div className="App">
			<LoginManager />
		</div>
    );
}

const LoginManager = () => {
	const navigate = useNavigate();

	const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);
	const [username, setUsername] = useState(localStorage.getItem('username') || '');
	const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

	const usernameInputRef = useRef(null); // Reference for the username input field
	const passwordInputRef = useRef(null); // Reference for the password input field

	useEffect(() => {
		import(`../../themes/${theme}.css`)
			.then(() => {
			})
			.catch(err => {
				console.error(`Failed to load ${theme} theme`, err);
			});
		document.body.className = theme;
	}, [theme]); // Re-run effect when theme changes

	async function loginUser(username, password) {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				credentials: 'include',
				body: JSON.stringify({ username, password })
			});
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			} else {
				const result = await response.json();
				//console.log(result); // Handle the response based on your app's needs
				// Assuming result has a flag or message indicating successful login
				if (result.loginSuccess) {
					setIsPasswordIncorrect(false);
					// Update UI or application state to reflect logged-in status
					navigate('/app'); // Navigate to the app
				} else {
					// Handle login failure (e.g., wrong credentials)
					setIsPasswordIncorrect(true);
					//alert(result.message || 'Login failed');
				}
			}
		} catch (error) {
			console.error("Error logging in:", error);
			// Display an error message or handle login error
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent default form submission behavior

		const username = usernameInputRef.current.value; // Get the current value of the username input
		const password = passwordInputRef.current.value; // Get the current value of the password input

		loginUser(username, password);
	};

	return (
		<>
			<div className="login-container">
				<h1>Welcome Back!</h1>
				<form id="loginUsernameForm" className="username-form" method="dialog" onSubmit={handleSubmit}>
					<div className="input-container">
						<md-outlined-text-field
							label="Enter Your Username"
							id="loginUsernameInput"
							value={username}
							ref={usernameInputRef}
							placeholder="e.g., JohnDoe123"
							required
							editable
							aria-label="Username"
						/>
					</div>
					<div className="input-container">
						<md-outlined-text-field
							label="Enter Your Password"
							id="loginPasswordInput"
							type="password"
							ref={passwordInputRef}
							placeholder="Password"
							required
							editable
							aria-label="Password"
						/>
					</div>
					<div className="form-actions">
						<md-filled-button type="submit">
							Continue
						</md-filled-button>
					</div>
				</form>
				{isPasswordIncorrect && <div className="error-message">Incorrect password. Please try again.</div>}
				<a href="#">Forgot password?</a>
				<span>Don't have an account? <Link to="/register">Sign up</Link></span>
				<Link to="/">Homepage</Link>
			</div>
		</>
	);
}

export default LoginPage;