/*TODO
add a explain grammar feature
convert to typescript?
add buttons to get a new response or edit your message
add a feature to detect when the conversation has concluded based on the user's goal
provide a short grammar and cultural nuance report at the end of the conversation. Can be split into two
Show the user how much they've improved in that short conversation with vocab or grammar
Highlight a word that's used X times, then fade out from there
For displaying grammatical syntax, create a filter for syntactic structures, allowing the user to see the original message, and the message without the modifiers that complicate the sentence.
Create a visual network of words encountered, and connect them to other words in the same sentence, or by proximity to the word. For example, the connection strength between re- and learn will be calculated by frequency seen together and distance from each other
Must fix morpheme detail information rendering quotations incorrectly. Should sanitize them.
*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import ScenarioManager from "./components/ScenarioManager";
import ConversationArea from "./components/ConversationArea";
import { useWebSocket } from "./components/WebSocketProvider"; // Import the useWebSocket hook
import SidePanel from "./components/SidePanel";

axios.defaults.withCredentials = true;

function App() {
  const [language, setLanguage] = useState();
  const [showTransliteration, setShowTransliteration] = useState();
  const [theme, setTheme] = useState();
  const [masteryThreshold, setMasteryThreshold] = useState();
  const [username, setUsername] = useState();
  const [userType, setUserType] = useState();
  const [affiliatedOrganization, setAffiliatedOrganization] = useState();
  const [conversationDetails, setConversationDetails] = useState({
    conversationId: "",
    conversationHistory: [],
  });
  const [initialConversationDetails, setInitialConversationDetails] = useState({
    conversationId: "",
    conversationHistory: [],
  });
  const [scenarioState, setScenarioState] = useState({
    reasonForLearning: "",
    additionalNotes: "",
    languageLearnerRole: "",
    conversationPartnerRole: "",
    informationToGive: "",
    informationToRequest: "",
    context: "",
  });

  const messageFieldRef = useRef(null);
  const menuButtonRef = useRef(null);

  const { ws, retryConnection } = useWebSocket();

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = useCallback(() => {
    setIsPanelOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  useEffect(() => {
    if (theme) {
      import(`./themes/${theme}.css`)
        .then(() => {})
        .catch((err) => {
          console.error(`Failed to load ${theme} theme`, err);
        });
      document.body.className = theme;
    }
  }, [theme]); // Re-run effect when theme changes

  useEffect(() => {
    const fetchConversationHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/request-conversation-history`
        );
        const { conversationDetails, preferences, scenario } = response.data;
        setInitialConversationDetails(conversationDetails);
        setConversationDetails(conversationDetails);
        setLanguage(preferences.language);
        setShowTransliteration(preferences.showTransliteration);
        setTheme(preferences.theme);
        setMasteryThreshold(preferences.masteryThreshold);
        setScenarioState(scenario);
      } catch (error) {
        console.error("Error fetching conversation history:", error);
      }
    };
    fetchConversationHistory();
  }, []);

  useEffect(() => {
    // Trigger the backend to check the HttpOnly cookie
    axios
      .get(`${process.env.REACT_APP_API_URL}/validateToken`, {
        withCredentials: true,
      }) // ensure to send withCredentials if cookies are used
      .then((response) => {
        if (response.data.valid) {
          setUsername(response.data.username);
          setUserType(response.data.userType);
          setAffiliatedOrganization(response.data.affiliatedOrganization);
        } else {
          console.log("Token validation failed:", response.data.message);
          setUsername(null);
          setUserType(null);
          setAffiliatedOrganization(null);
        }
      })
      .catch((error) => {
        console.error("Error validating token:", error);
        setUsername(null);
        setUserType(null);
        setAffiliatedOrganization(null);
      });
    retryConnection();
  }, [retryConnection]);

  useEffect(() => {
    if (!ws) return;

    const onMessage = (event) => {
      const data = JSON.parse(event.data);
      //console.log("onMessage data: ", data);
      // Update preferences if present in the incoming message
      data.username && setUsername(data.username);
      data.userType && setUserType(data.userType);
      data.affiliatedOrganization &&
        setAffiliatedOrganization(data.affiliatedOrganization);
    };

    ws.addEventListener("message", onMessage);
    return () => {
      if (ws) {
        ws.removeEventListener("message", onMessage);
      }
    };
  }, [ws]);

  const handleResetConversation = async () => {
    const messageField = messageFieldRef.current;
    messageField.value = "";
    localStorage.removeItem("myInputValue");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/reset-conversation`
      );
    } catch (error) {
      console.error("Error resetting conversation:", error);
    }
  };

  const handleEndConversation = async () => {
    //This should be changed, here and in the backend
    const messageField = messageFieldRef.current;
    messageField.value = "";
    localStorage.removeItem("myInputValue");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/save-conversation`
      );
    } catch (error) {
      console.error("Error ending conversation:", error);
    }
  };

  return (
    <div className={theme}>
      <div className="App">
        <div className="app-container">
          <header className="app-header">
            <span className="navigation-menu-button">
              <md-icon-button ref={menuButtonRef} onClick={togglePanel}>
                <md-icon>menu</md-icon>
              </md-icon-button>
            </span>
            <ConversationOptionsMenu
              handleResetConversation={handleResetConversation}
              scenarioState={scenarioState}
              setScenarioState={setScenarioState}
            />
          </header>
          {scenarioState.informationToGive &&
            scenarioState.informationToRequest && (
              <ScenarioReminder scenarioState={scenarioState} />
            )}
          <SidePanel
            isPanelOpen={isPanelOpen}
            togglePanel={togglePanel}
            conversationDetails={conversationDetails}
            setConversationDetails={setConversationDetails}
            setInitialConversationDetails={setInitialConversationDetails}
            setScenarioState={setScenarioState}
            handleResetConversation={handleResetConversation}
            userType={userType}
            menuButtonRef={menuButtonRef}
            language={language}
            setLanguage={setLanguage}
            showTransliteration={showTransliteration}
            setShowTransliteration={setShowTransliteration}
            theme={theme}
            setTheme={setTheme}
            masteryThreshold={masteryThreshold}
            setMasteryThreshold={setMasteryThreshold}
          />
          <ConversationArea
            showTransliteration={showTransliteration}
            language={language}
            conversationDetails={conversationDetails}
            setConversationDetails={setConversationDetails}
            initialConversationDetails={initialConversationDetails}
            setInitialConversationDetails={setInitialConversationDetails}
            masteryThreshold={masteryThreshold}
            setMasteryThreshold={setMasteryThreshold}
          />
          <MessageInput
            messageFieldRef={messageFieldRef}
            language={language}
            showTransliteration={showTransliteration}
          />
        </div>
      </div>
    </div>
  );
}

const MessageInput = ({ messageFieldRef, language, showTransliteration }) => {
  // Update local storage whenever the input value changes

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    localStorage.setItem("myInputValue", newValue);
  };

  useEffect(() => {
    const messageField = messageFieldRef.current;
    messageField.value = localStorage.getItem("myInputValue") || "";
    autoResizeTextarea(messageFieldRef);
  }, []);

  const autoResizeTextarea = (fieldRef, minRows = 1, maxRows = 4) => {
    // Constants for the textarea resizing
    const LINE_HEIGHT = 24;
    const inputField = fieldRef.current;
    if (!inputField) return;
    const shadowRoot = inputField.shadowRoot;
    if (!shadowRoot) return;
    const inputElement = shadowRoot.querySelector(".input");
    if (!inputElement) return;
    inputElement.style.overflow = "hidden";
    inputElement.style.height = "auto";
    let desiredHeight = inputElement.scrollHeight;
    let minHeight = LINE_HEIGHT * minRows;
    let maxHeight = LINE_HEIGHT * maxRows;
    desiredHeight = Math.min(Math.max(desiredHeight, minHeight), maxHeight);
    inputElement.style.height = `${desiredHeight}px`;
    inputElement.style.overflow = "";
  };

  // Submit handler for the message form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const messageField = messageFieldRef.current;
    const message = messageField.value.trim();
    if (!message) return;
    messageField.value = "";
    localStorage.removeItem("myInputValue");
    try {
      //console.log(process.env.REACT_APP_API_URL);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/send-message`,
        {
          message,
          language,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <form className="message-form" onSubmit={handleSubmit}>
      <md-outlined-text-field
        id="userInput"
        type="textarea"
        ref={messageFieldRef}
        rows="1"
        placeholder="Type your mixed language message..."
        onInput={(e) => {
          autoResizeTextarea(messageFieldRef);
          handleInputChange(e);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            handleSubmit(e);
            e.preventDefault();
            autoResizeTextarea(messageFieldRef);
          }
        }}
      />
      <md-icon-button type="submit" className="send-button">
        <md-icon filled>send</md-icon>
      </md-icon-button>
    </form>
  );
};
const ConversationOptionsMenu = ({
  handleResetConversation,
  scenarioState,
  setScenarioState,
}) => {
  const scenarioMenuItemRef = useRef(null);
  const conversationOptionsButtonRef = useRef(null);
  const scenarioManagerRef = useRef(null);
  const languageMenuRef = useRef(null);
  const [hasOpenedScenarioManager, setHasOpenedScenarioManager] =
    useState(false);
  const dialogRefLanguage = useRef(null);

  const openScenarioManager = () => {
    scenarioManagerRef.current.openScenarioManagerDefault();
    setHasOpenedScenarioManager(true); // Set the state to true when the Scenario Manager is opened
    if (conversationOptionsButtonRef.current) {
      conversationOptionsButtonRef.current.classList.remove(
        "pulse",
        "pulse-round"
      );
    }
    if (scenarioMenuItemRef.current) {
      scenarioMenuItemRef.current.classList.remove("pulse");
    }
  };
  const openLanguageManager = () => {
    dialogRefLanguage.current.open = true;
  };
  // Tutorial highlight
  useEffect(() => {
    if (!hasOpenedScenarioManager) {
      if (conversationOptionsButtonRef.current) {
        conversationOptionsButtonRef.current.classList.add(
          "pulse",
          "pulse-round"
        );
        conversationOptionsButtonRef.current.style.position = "relative";
      }
      if (scenarioMenuItemRef.current) {
        scenarioMenuItemRef.current.classList.add("pulse");
        scenarioMenuItemRef.current.style.position = "relative";
      }
    }
  }, [hasOpenedScenarioManager]); // Depend on the hasOpenedScenarioManager state

  // Function to setup menu toggle
  function setupMenuToggle(menuSelector, anchorSelector) {
    // Function to toggle the menu
    const toggleMenu = () => {
      const menuEl = document.body.querySelector(menuSelector);
      if (menuEl) {
        menuEl.open = !menuEl.open;
        // Check if the menu is now open or closed
        if (menuEl.open) {
          // If the menu is open, remove the pulse effect
          conversationOptionsButtonRef.current.classList.remove(
            "pulse",
            "pulse-round"
          );
        }
      }
    };
    // Get the anchor element
    const anchorEl = document.body.querySelector(anchorSelector);
    // Add event listener to the anchor element
    if (anchorEl) {
      anchorEl.addEventListener("click", toggleMenu);
    }
    // Cleanup function to remove the event listener
    return () => {
      if (anchorEl) {
        anchorEl.removeEventListener("click", toggleMenu);
      }
    };
  }
  const submenu = languageMenuRef.current;
  if (submenu) {
    submenu.style.overflowY = "auto";
    submenu.style.overflowX = "hidden";
  }
  useEffect(() => {
    // Setup for first menu
    const cleanupFirstMenu = setupMenuToggle(
      "#conversation-options-menu",
      "#conversation-options-menu-anchor"
    );

    // Setup for second menu (if applicable)
    //const cleanupSecondMenu = setupMenuToggle('#languages-menu', '#options-submenu-anchor');

    // Cleanup function to remove event listeners
    return () => {
      cleanupFirstMenu();
      //cleanupSecondMenu();
    };
  }, []);

  return (
    <>
      <span className="conversation-options-menu-button">
        <md-icon-button
          id="conversation-options-menu-anchor"
          ref={conversationOptionsButtonRef}
        >
          <md-icon>more_vert</md-icon>
        </md-icon-button>
        <div className="conversation-options">
          <md-menu
            has-overflow
            id="conversation-options-menu"
            anchor="conversation-options-menu-anchor"
          >
            <md-menu-item
              ref={scenarioMenuItemRef}
              onClick={openScenarioManager}
            >
              <div slot="headline" aria-label="Open a form dialog">
                Scenario Manager
              </div>
            </md-menu-item>
            <md-menu-item onClick={handleResetConversation}>
              <div slot="headline">Reset Conversation</div>
            </md-menu-item>
          </md-menu>
          <ScenarioManager
            ref={scenarioManagerRef}
            scenarioState={scenarioState}
            setScenarioState={setScenarioState}
          />
        </div>
      </span>
    </>
  );
};

const ScenarioReminder = ({ scenarioState }) => {
  const dialogRefScenarioReminder = useRef(null);

  const openScenarioReminderDialog = () => {
    dialogRefScenarioReminder.current.open = true;
  };
  const closeDialog = (e) => {
    dialogRefScenarioReminder.current.open = false;
  };
  return (
    <>
      <span className="scenario-reminder-button">
        <md-icon-button onClick={openScenarioReminderDialog}>
          <md-icon>menu_open</md-icon>
        </md-icon-button>
      </span>
      <div className="scenario-reminder-dialog">
        <md-dialog ref={dialogRefScenarioReminder}>
          <span slot="headline" className="headline">
            Scenario Reminder
            <md-icon-button
              class="dialog-close-button"
              value="close"
              aria-label="Close Scenario Reminder"
              onClick={() => {
                closeDialog();
              }}
            >
              <md-icon>close</md-icon>
            </md-icon-button>
          </span>
          <div slot="content">
            <div className="scenario-reminder-content">
              {scenarioState.informationToGive}
            </div>
            <div className="scenario-reminder-content">
              {scenarioState.informationToRequest}
            </div>
          </div>
        </md-dialog>
      </div>
    </>
  );
};

export default App;
