import React, {
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
  useState,
} from "react";
import axios from "axios";
import { useWebSocket } from "./WebSocketProvider"; // Import the useWebSocket hook
import JSONAutocomplete from "json-autocomplete";
import { processChunk } from "./chunkManagement";

axios.defaults.withCredentials = true;
const ScenarioManager = forwardRef((props, ref) => {
  const { scenarioState, setScenarioState } = props;
  const dialogRefCustomization = useRef(null);
  const dialogRefConfirmation = useRef(null);

  const handleInputChange = (field, value) => {
    setScenarioState((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const openScenarioManagerDefault = () => {
    handlePageChange("customization");
  };

  const openDialog = (dialogRef) => {
    if (dialogRef.current) {
      dialogRef.current.open = true;
    }
  };

  const closeDialog = (dialogRef) => {
    if (dialogRef.current) {
      dialogRef.current.open = false;
    }
  };

  useImperativeHandle(ref, () => ({
    openScenarioManagerDefault,
    openDialog: openDialog,
  }));

  const handlePageChange = (page) => {
    if (page === "customization") {
      openDialog(dialogRefCustomization);
      closeDialog(dialogRefConfirmation);
    } else if (page === "confirmation") {
      openDialog(dialogRefConfirmation);
      closeDialog(dialogRefCustomization);
    }
  };

  const createScenario = async () => {
    const scenarioData = {
      reasonForLearning: scenarioState.reasonForLearning,
      additionalNotes: scenarioState.additionalNotes,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-scenario`,
        scenarioData,
        {
          withCredentials: true,
        }
      );

      const responseData = response.data;

      setScenarioState((prevDetails) => ({
        ...prevDetails,
        ...responseData,
      }));
    } catch (error) {
      console.error(
        "Error creating the scenario:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const areFieldsEmpty = (fields) => {
    return fields.every((field) => !field || field.trim() === "");
  };

  const handleNext = async () => {
    handlePageChange("confirmation");

    const fieldsToCheck = [
      scenarioState.languageLearnerRole,
      scenarioState.conversationPartnerRole,
      scenarioState.informationToGive,
      scenarioState.informationToRequest,
      scenarioState.context,
    ];

    if (areFieldsEmpty(fieldsToCheck)) {
      createScenario();
    }
  };

  const handleBack = () => {
    handlePageChange("customization");
  };

  const handleConfirm = async () => {
    closeDialog(dialogRefConfirmation);
    const scenarioData = {
      ...scenarioState,
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/initialize-conversation-storage`,
        scenarioData,
        {
          withCredentials: true,
        }
      );
      await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-scenario`,
        scenarioData,
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error(
        "Error updating the scenario:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const autoResizeTextarea = (ref) => {
    const messageField = ref.current;
    if (!messageField) {
      return;
    }
    const shadowRoot = messageField.shadowRoot;
    if (!shadowRoot) {
      return;
    }
    const inputElement = shadowRoot.querySelector(".input");
    if (!inputElement) {
      return;
    }
    inputElement.style.overflow = "hidden";
    inputElement.style.height = "auto";
    let desiredHeight = inputElement.scrollHeight;
    if (desiredHeight < 24) {
      desiredHeight = 24;
    } else if (desiredHeight > 96) {
      desiredHeight = 96;
    }
    inputElement.style.height = `${desiredHeight}px`;
    inputElement.style.overflow = "";
  };

  return (
    <>
      <md-dialog
        ref={dialogRefCustomization}
        className="scenario-customization"
      >
        <CustomizeScenarioComponent
          handleInputChange={handleInputChange}
          handleNext={handleNext}
          autoResizeTextarea={autoResizeTextarea}
          scenarioState={scenarioState}
          setScenarioState={setScenarioState}
        />
      </md-dialog>
      <md-dialog ref={dialogRefConfirmation} className="ScenarioConfirmation">
        <ConfirmScenarioComponent
          handleInputChange={handleInputChange}
          handleBack={handleBack}
          handleConfirm={handleConfirm}
          createScenario={createScenario}
          autoResizeTextarea={autoResizeTextarea}
          setScenarioState={setScenarioState}
          scenarioState={scenarioState}
        />
      </md-dialog>
    </>
  );
});

const CustomizeScenarioComponent = ({
  handleInputChange,
  handleNext,
  autoResizeTextarea,
  scenarioState,
  setScenarioState,
}) => {
  const reasonForLearningRef = useRef(null);
  const additionalNotesRef = useRef(null);

  const resetCustomization = async () => {
    setScenarioState((prevDetails) => ({
      ...prevDetails,
      reasonForLearning: "",
      additionalNotes: "",
    }));
  };

  useEffect(() => {
    const resetFieldIfCurrentExists = (ref) => {
      if (ref.current) ref.current.reset();
    };

    resetFieldIfCurrentExists(reasonForLearningRef);
    resetFieldIfCurrentExists(additionalNotesRef);
  }, [scenarioState]);

  return (
    <>
      <span slot="headline" className="headline">
        Customize Your Scenario
        <md-icon-button
          form="scenarioForm"
          value="close"
          aria-label="Close dialog"
        >
          <md-icon>close</md-icon>
        </md-icon-button>
      </span>
      <form
        id="scenarioForm"
        slot="content"
        method="dialog"
        className="scenario-content"
      >
        <div className="scenario-row scenario-manager">
          <md-filled-text-field
            ref={reasonForLearningRef} // Use the ref directly here
            required
            value={scenarioState.reasonForLearning} // Bind the value to the state
            onInput={(e) => {
              autoResizeTextarea(reasonForLearningRef); // Pass the ref here
              handleInputChange("reasonForLearning", e.target.value);
            }}
            editable
            label="Reason for learning"
            placeholder="e.g., Business, Travel, Hobby"
            autofocus
          />
        </div>
        <div className="scenario-row scenario-manager">
          <md-filled-text-field
            type="textarea"
            rows="3"
            ref={additionalNotesRef} // Use the ref directly here
            value={scenarioState.additionalNotes} // Bind the value to the state
            onInput={(e) => {
              autoResizeTextarea(additionalNotesRef);
              handleInputChange("additionalNotes", e.target.value);
            }}
            editable
            label="Additional notes"
            placeholder="e.g., Preferred topics, etc."
          />
        </div>
      </form>
      <div slot="actions">
        <md-text-button onClick={resetCustomization}>Reset</md-text-button>
        <md-filled-button onClick={handleNext}>Next</md-filled-button>
      </div>
    </>
  );
};

const ConfirmScenarioComponent = ({
  handleInputChange,
  handleBack,
  handleConfirm,
  createScenario,
  autoResizeTextarea,
  scenarioState,
  setScenarioState,
}) => {
  const languageLearnerRoleRef = useRef(null);
  const conversationPartnerRoleRef = useRef(null);
  const informationToGiveRef = useRef(null);
  const informationToRequestRef = useRef(null);
  const contextRef = useRef(null);

  const { ws, retryConnection } = useWebSocket();
  useEffect(() => {
    if (!ws) return;
    const onMessage = async (event) => {
      const data = JSON.parse(event.data);
      //console.log("data: ", data);
      if (data.key && data.chunkId !== undefined) {
        if (data.key === "scenarioChunks") {
          //console.log("chunkid: ", data.chunkId, " chunk: ", data.chunk);
          const temporaryChunkStorage = await processChunk(data); // Await the async function
          if (temporaryChunkStorage) {
            updateScenarioFields(data, temporaryChunkStorage);
          }
        }
      }
    };
    function updateScenarioFields(data, temporaryChunkStorage) {
      //console.log("temporaryChunkStorage: ", JSON.parse(JSON.stringify(temporaryChunkStorage)));
      if (!temporaryChunkStorage || !temporaryChunkStorage.chunks) {
        console.error(`No temporaryChunkStorage found for scenario`);
        return;
      }
      // Sort chunkIds numerically to ensure correct order
      const sortedChunkIds = Object.keys(temporaryChunkStorage.chunks)
        .map(Number)
        .sort((a, b) => a - b);
      // Combine chunks based on sorted order
      const combinedData = sortedChunkIds
        .map((chunkId) => temporaryChunkStorage.chunks[chunkId].chunk)
        .join("");
      //console.log("temporaryChunkStorage: ", JSON.parse(JSON.stringify(temporaryChunkStorage)));
      try {
        const completeJsonString = JSONAutocomplete(combinedData); // Assuming JSONAutocomplete is defined elsewhere
        const scenarioDetails = JSON.parse(completeJsonString);
        //console.log("scenarioDetails", scenarioDetails);

        // Define the order of fields corresponding to scenarioDetails keys
        const fieldsOrder = [
          "languageLearnerRole", // Key "0"
          "conversationPartnerRole", // Key "1"
          "informationToGive", // Key "2"
          "informationToRequest", // Key "3"
          "context", // Key "4"
        ];

        // Use the functional form of setScenarioState to ensure the latest state is used
        setScenarioState((prevState) => {
          // Create a copy of the previous state to avoid direct mutations
          const updatedState = { ...prevState };

          // Safely check if scenarioDetails is defined and is an object
          if (scenarioDetails && typeof scenarioDetails === "object") {
            // Iterate over each key-value pair in scenarioDetails
            for (const [key, value] of Object.entries(scenarioDetails)) {
              // Convert the key to a zero-based index
              const index = parseInt(key, 10); // Direct mapping without subtraction

              // Check if the index is within the bounds of fieldsOrder
              if (index >= 0 && index < fieldsOrder.length) {
                const fieldName = fieldsOrder[index];

                // Update the corresponding field in updatedState
                updatedState[fieldName] = value ?? "";

              } else {
                console.warn(
                  `Unrecognized key "${key}" in scenarioDetails. No mapping found.`
                );
              }
            }
          } else {
            //console.warn("scenarioDetails is null or not an object.");
          }

          return updatedState;
        });

        //setScenarioState(scenarioDetails);
      } catch (error) {
        // Log or handle error as needed
        //console.error("Error processing scenario details:", error);
      }
    }
    ws.addEventListener("message", onMessage);
    return () => {
      ws.removeEventListener("message", onMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws]);

  const resetConfirmation = async () => {
    setScenarioState((prevDetails) => ({
      ...prevDetails,
      languageLearnerRole: "",
      conversationPartnerRole: "",
      informationToGive: "",
      informationToRequest: "",
      context: "",
    }));

    createScenario();
  };

  useEffect(() => {
    const resetFieldIfCurrentExists = (ref) => {
      if (ref.current) ref.current.reset();
    };

    resetFieldIfCurrentExists(languageLearnerRoleRef);
    resetFieldIfCurrentExists(conversationPartnerRoleRef);
    resetFieldIfCurrentExists(informationToGiveRef);
    resetFieldIfCurrentExists(informationToRequestRef);
    resetFieldIfCurrentExists(contextRef);
  }, [scenarioState]);

  return (
    <>
      <span slot="headline" className="headline">
        Review Your Scenario
        <md-icon-button
          form="confirmationForm"
          value="close"
          aria-label="Close dialog"
        >
          <md-icon>close</md-icon>
        </md-icon-button>
      </span>
      <form
        id="confirmationForm"
        slot="content"
        method="dialog"
        className="confirmation-content"
      >
        <div className="scenario-row scenario-manager">
          <md-filled-text-field
            ref={languageLearnerRoleRef}
            required
            value={scenarioState.languageLearnerRole}
            onInput={(e) => {
              autoResizeTextarea(languageLearnerRoleRef);
              handleInputChange("languageLearnerRole", e.target.value);
            }}
            editable
            label="Language Learner Role"
            placeholder="e.g., Student, Tourist"
          />
        </div>
        <div className="scenario-row scenario-manager">
          <md-filled-text-field
            ref={conversationPartnerRoleRef}
            required
            value={scenarioState.conversationPartnerRole}
            onInput={(e) => {
              autoResizeTextarea(conversationPartnerRoleRef);
              handleInputChange("conversationPartnerRole", e.target.value);
            }}
            editable
            label="Conversation Partner Role"
            placeholder="e.g., Teacher, Local Resident"
          />
        </div>
        <div className="scenario-row scenario-manager">
          <md-filled-text-field
            ref={informationToGiveRef}
            type="textarea"
            rows="2"
            value={scenarioState.informationToGive}
            onInput={(e) => {
              autoResizeTextarea(informationToGiveRef);
              handleInputChange("informationToGive", e.target.value);
            }}
            editable
            label="Information to Give"
            placeholder="e.g., Personal details, Preferences"
          />
        </div>
        <div className="scenario-row scenario-manager">
          <md-filled-text-field
            ref={informationToRequestRef}
            type="textarea"
            rows="2"
            value={scenarioState.informationToRequest}
            onInput={(e) => {
              autoResizeTextarea(informationToRequestRef);
              handleInputChange("informationToRequest", e.target.value);
            }}
            editable
            label="Information to Request"
            placeholder="e.g., Directions, Recommendations"
          />
        </div>
        <div className="scenario-row scenario-manager">
          <md-filled-text-field
            ref={contextRef}
            type="textarea"
            rows="3"
            value={scenarioState.context}
            onInput={(e) => {
              autoResizeTextarea(contextRef);
              handleInputChange("context", e.target.value);
            }}
            editable
            label="Context"
            placeholder="e.g., Formal meeting, Casual conversation"
          />
        </div>
      </form>
      <div slot="actions">
        <md-text-button onClick={resetConfirmation}>Reset</md-text-button>
        <md-text-button onClick={handleBack}>Back</md-text-button>
        <md-filled-button autofocus onClick={handleConfirm}>
          Confirm
        </md-filled-button>
      </div>
    </>
  );
};

export default ScenarioManager;
