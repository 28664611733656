import axios from 'axios';

export const listDatabases = () => {
    //console.log("listDatabases endpoint triggered");
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard/listDatabases`);
};
export const listActions = (modelName) => {
    //console.log("listActions endpoint triggered");
    if (!modelName) {
        throw new Error("Model name must be provided to fetch data.");
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard/listActions/${modelName}`);
};

export const fetchDatabaseData = (modelName) => {
    // Check if modelName is provided
    if (!modelName) {
        throw new Error("Model name must be provided to fetch data.");
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard/fetchDatabaseData/${modelName}`);
};

export const addAccessCode = () => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/accessCodes/addAccessCode`);
};

export const deleteDatabaseEntry = (modelName, itemId) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/admin/dashboard/deleteDatabaseEntry/${modelName}/${itemId}`, {
        data: { modelName, itemId }
    });
};

export const updateDatabaseEntry = (modelName, itemId, updateData) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/admin/dashboard/updateDatabaseEntry/${modelName}/${itemId}`, updateData);
};

export const addDatabaseEntry = (modelName, newData = {}) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/dashboard/addDatabaseEntry/${modelName}`, newData);
};

export const resetUserPassword = (userId) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/users/resetUserPassword/${userId}`);
};

/*export const listUsers = () => {
    //console.log("users listed endpoint triggered");
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/users`);
};

export const addUser = (userData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/users/add`, userData);
};

export const editUser = (userId, userData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/users/edit/${userId}`, userData);
};

export const deleteUser = (userId) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/users/delete/${userId}`);
};
*/